.blog {
  min-height: calc(100vh - 10rem);
  margin: 0 auto;
  width: 70%;
}

.blog .title {
  color: var(--primary-color);
  margin-top: 0;
}

.blog img {
  height: 15rem;
}

.blog .text {
  display: flex;
  align-items: flex-start;
}

.blog .text div {
  margin-left: 2rem;
}

.blog .text p {
  margin-top: 0;
}

.blog-posts {
  margin: 3rem 0;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;
}

@media only screen and (max-width: 768px) {
  .blog-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .blog {
    width: 90%;
  }
  .blog-posts {
    grid-template-columns: repeat(1, 1fr);
  }
}