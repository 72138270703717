.blog-card {
  width: 100%;
}

.blog-card .media {
  width: 100%;
  height: 25rem;
}
.blog-card .MuiCardActions-root {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.blog-card .MuiCardContent-root p {
  font-weight: normal;
  text-align: left;
}

.blog-card .MuiSvgIcon-root {
  fill: white;
}

.blog-title {
  display: flex;
  flex-direction: column;
}

.blog-card h3,
.blog-card h4 {
  margin: 0;
}
.blog-card h3 {
  font-size: .8rem;
  text-align: right;
}
.blog-card .MuiCardActions-spacing > :not() {
  margin: 0 !important;
}