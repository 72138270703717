@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
html, body {
  width: 100%;
  height: 100%;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f8ff;
  color: #333;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #114bb2;
  --text-color: #333;
}

.wave {
  background: white;
  width: 100%;
  height: 40rem;
  position: relative;
}
.wave:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(/static/media/wave.8a56bc55.svg);
  background-size: cover;
  width: 100%;
  height: 20rem;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40rem;
  z-index: -1;
}
.MuiButtonBase-root {
  background-color: #114bb2 !important;
  background-color: var(--primary-color) !important;
}
.MuiButton-root {
  color: white !important; 
  border-radius: 0;
  width: 12vw;
  height: 2vw;
  font-size: .8vw;
}

.header {
  width: 100%;
  height: 4rem;
  background: white;
  padding: 1rem 0;
  display: flex;
  justify-content: center; 
  position: sticky;
  position: -webkit-sticky; 
  top: 0;
  z-index: 999;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 70%;
}
.logo {
  height: 100%;
  width: auto;
}
.logo img {
  height: 100%;
}
.links {
  display: flex;
  align-items: center;
}
.links .MuiTypography-root {
  color: var(--primary-color);
  margin-left: 2rem;
}
.burger-button {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .nav-container { 
    width: 90%;
  }
  .drawerMenu .MuiTypography-body1 {
    font-size: 2rem;
  }
  .linkedin .MuiSvgIcon-root {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .nav-container { 
    width: 90%;
  }
  .links {
    display: none;
  }
  .burger-button {
    display: block
  }
}
.footer {
  width: 100%;
  height: 2rem;
  background: var(--primary-color);
  color: white;
  padding: 1rem 0;
  display: flex;
  justify-content: center; 
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  text-align: center;
}

.footer h2 {
  font-family: 'Roboto';
  font-size: .8rem;
  width: 33%;
}
.article {
  width: 70%;
  min-height: calc(100vh - 10rem);
  margin: 0 auto;
  text-align: justify;
}

.article h1 {
  margin: 0;
}

.author-date {
  margin-top: 0;
  font-weight: 300; 
  color: "#555";
}

.case-article {
  width: 70%;
  margin: 0 auto;
  text-align: justify;
}

.case-article .featured-image,
.article .featured-image {
  width: 50%;
  height: auto;
  float: left;
  margin-right: 1rem;
  margin-bottom: .5rem;
}

.article img {
  width: 50%;
  height: auto;
  margin-right: 1rem;
  margin-bottom: .5rem;
}

.case-article p:last-of-type,
.article p:last-of-type {
  margin-bottom: 5rem;
}

.case-article h1,
.article h1 {
  color: var(--primary-color)
}

@media only screen and (max-width: 768px) {
  .case-article,
  .article {
    width: 90%;
    text-align: justify;
  }
  .case-article .featured-image,
  .article .featured-image {
    float: unset;
    width: 100%;
  }
}
.blog-card {
  width: 100%;
}

.blog-card .media {
  width: 100%;
  height: 25rem;
}
.blog-card .MuiCardActions-root {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.blog-card .MuiCardContent-root p {
  font-weight: normal;
  text-align: left;
}

.blog-card .MuiSvgIcon-root {
  fill: white;
}

.blog-title {
  display: flex;
  flex-direction: column;
}

.blog-card h3,
.blog-card h4 {
  margin: 0;
}
.blog-card h3 {
  font-size: .8rem;
  text-align: right;
}
.blog-card .MuiCardActions-spacing > :not() {
  margin: 0 !important;
}
.blog {
  min-height: calc(100vh - 10rem);
  margin: 0 auto;
  width: 70%;
}

.blog .title {
  color: var(--primary-color);
  margin-top: 0;
}

.blog img {
  height: 15rem;
}

.blog .text {
  display: flex;
  align-items: flex-start;
}

.blog .text div {
  margin-left: 2rem;
}

.blog .text p {
  margin-top: 0;
}

.blog-posts {
  margin: 3rem 0;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;
}

@media only screen and (max-width: 768px) {
  .blog-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .blog {
    width: 90%;
  }
  .blog-posts {
    grid-template-columns: repeat(1, 1fr);
  }
}
.case-card {
  width: 100%;
  pointer-events: none;
}

.case-card .media {
  width: 100%;
  height: 17rem;
}
.case-card .MuiCardActions-root {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.case-card .MuiCardContent-root p {
  font-weight: normal;
  text-align: right;
}

.case-card .MuiSvgIcon-root {
  fill: white;
}

.case-title {
  display: flex;
  flex-direction: column;
}

.case-card h3,
.case-card h4 {
  margin: 0;
  text-align: right;
}
.case-card h3 {
  font-size: .9rem;
}
.case-card h4 {
  font-size: .8rem;
}

@media only screen and (max-width: 768px) {
  .case-card h3 {
    font-size: .7rem;
  }
  .case-card h4 {
    font-size: .6rem;
  }
}
.case {
  min-height: calc(100vh - 10rem);
  margin: 0 auto;
  width: 70%;
}

.case .title {
  text-align: center;
  color: var(--primary-color);
  font-size: 3rem;
}

.case .text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  position: relative;
}

.case .text p {
  margin-top: 0;
}

.cases {
  margin: 3rem 0;
}

.case-img {
  position: absolute;
  width: 15rem;
  height: auto;
  float: right;
  z-index: 10;
  right: -10%;
  bottom: -5%;
}
.coverflow-container {
  position: relative;
}

.case-text {
  margin-bottom: 5rem;
}

.cases {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;
}
.case .swiper-container {
  padding-bottom: 3rem;
}

.case .swiper-slide.swiper-slide-prev,
.case .swiper-slide.swiper-slide-next {
  pointer-events: none;
}

.case .swiper-slide.swiper-slide:not(.swiper-slide-active) .case-title {
  opacity: 0;
  transition: opacity .5s ease;
}

.case .swiper-slide.swiper-slide-active::before {
  opacity: 0;
  transition: opacity .5s ease;
}
.case .swiper-slide.swiper-slide-active {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .case {
    width: 90%;
  }
  .case .text div {
    margin-left: 0;
  }
  .case .title {
    text-align: left;
  }
}
.team-card .media {
  width: 100%;
  height: 25rem;
}
.team-card .MuiCardActions-root {
  position: relative;
  display: flex;
}

.team-card h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;  
}

.team-card .MuiCardContent-root p {
  font-weight: normal;
  text-align: left;
}

.team-card .MuiSvgIcon-root {
  fill: white;
}
.linkedin .MuiSvgIcon-root {
  fill: var(--primary-color);
}
.linkedin.MuitButton-root {
  padding-left: 1rem;
}
.linkedin.MuiButtonBase-root {
  border-radius: 5px;
  background: transparent !important;
}
@media only screen and (max-width: 1024px) {
  .team-card h3 {
    font-size: .8rem;
    right: 3%;
    transform: translate(0,-50%);
  }
}
@media only screen and (max-width: 1024px) {
  .team-card h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%);
    margin: 0;  
    font-size: 1rem;
  }
}
.contact-form .MuiButtonBase-root {
  margin-top: 2rem;
}
.contact-form {
  width: 70%; 
  margin: 0 auto;
  background: white;
  padding: 1rem;
  margin-top: 2rem;
}
@media only screen and (max-width: 768px) {
  .contact-form {
    width: 90%; 
  }
}
.home {
  width: 70%;
  margin: 0 auto;
}
.home:target {
  display: block;
  position: relative;
  top: -120px; 
  visibility: hidden;
}
/* Splash section */
.section {
  height: calc(100vh - 6rem);
  width: 80vw;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  transform: translateY(-2rem);
}
.splash .img {
  width: 70%;
  height: auto;
  z-index: -1; 
}
.splash .text {
  position: absolute;
  width: 35vw;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.2rem;
  -webkit-filter: drop-shadow(-2px 2px 5px #aaa);
          filter: drop-shadow(-2px 2px 5px #aaa);
}
.section .MuiButtonBase-root {
  width: 27%;
  height: 2rem;
}
@media only screen and (max-width: 1024px) {
  .home {
    width: 90%;
  }
  .section {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: unset;
  }
  .splash .img {
    width: 80%;
  }
  .text { 
    display: flex;
    flex-direction: column;
    padding-right: unset;
    text-align: left;
  }
  .section .MuiButtonBase-root {
    width: 30%;
    height: 2rem;
  }
  .splash .text {
    position: relative;
    width: 100%;
    -webkit-filter: none;
            filter: none;
  }
}
@media only screen and (max-width: 768px) {
  .section .MuiButtonBase-root {
    width: 100%;
    height: 2rem;
  }
}

/* Selling points */
.selling-points {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15rem;
  scroll-margin-top: 10rem;
}
.selling-points .icon {
  width: 5rem;
  height: 5rem;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.icon .MuiSvgIcon-root {
  fill: white;
  height: 3rem;
  width: 3rem;
}
.selling-point {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}
.selling-point:not(last-of-type) {
  margin-bottom: 3rem;
}
.selling-points h1 {
  text-align: center;
  margin-bottom: 3rem;
}
.selling-point h2 {
  width: 85%;
  font-weight: normal;
  font-size: 1.4rem;
}
@media only screen and (max-width: 1024px) {
  .selling-points {
    scroll-margin-top: 8rem;
  }
  .selling-point { 
    align-items: flex-start;
  }
  .selling-point:not(last-of-type) {
    margin-bottom: 2rem;
  }
  .selling-points .icon {
    height: 3rem;
    width: 3rem;
  }
  .icon .MuiSvgIcon-root {
    height: 1rem;
    width: 1rem;
  }
  .selling-point h2 {
    width: 80%;
    margin:0;
    text-align: left;
  }
}
/* Mission */
.mission {
  margin: 2rem 0;
  margin-bottom: 15rem;
  scroll-margin-top: 10rem;
}
.mission h1 {
  color: var(--primary-color);
  margin: .5rem 0;
  font-size: 2rem;
}
.mission .text {
  display: flex;
  align-items: center;
  padding: 0;
  text-align: right;
}
.mission .text div {
  padding-left: 3rem;
  font-size: 1.4rem;
}
.mission .text img {
  width: 50%;
  height: auto;
}
.mission .text p:first-of-type {
  margin-top: 0;
}
.mission .text p:last-of-type {
  margin-bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .mission {
    scroll-margin-top: 8rem;
  }
  .mission .text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mission .text div {
    position: relative;
    left: unset;
    padding-left: unset;
    text-align: justify;
    width: 100%;
  }
  .mission .text img {
    width: 100%;
  }
}
/* Team */
.team {
  margin-bottom: 15rem;
  scroll-margin-top: 10rem;
}
.team h1 {
  color: var(--primary-color);
  margin: .5rem 0;
  font-size: 2rem;
}
.team p {
  font-size: 1.4rem;
}
.team-cards {
  display: flex;
  justify-content: center;
}
.team-cards .team-card {
  width: calc(100%/3);
  padding: 1rem;
}
.team-card .MuiCardContent-root {
  font-weight: bold;
  margin: 0 auto;
}
.team-card .MuiCardContent-root p {
  text-align: justify;
  padding: 0 1rem;
}
@media only screen and (max-width: 768px) {
  .team {
    scroll-margin-top: 8rem;
  }
  .team p {
    text-align: justify;
  }
  .team-cards {
    display: flex;
    flex-wrap: wrap;
  }
  .team-cards .team-card {
    width: 100%;
    padding: unset;
    margin-bottom: 1rem;
  }
}
/* Contact */
.contact {
  text-align: center;
  margin-bottom: 5rem;
  scroll-margin-top: 10rem;
}
.contact h1 {
  color: var(--primary-color);
  font-size: 2rem;
}
.contact h2 {
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: normal;
  width: 80%;
  margin-bottom: 2rem;
}
.logos {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0 auto;
}
.clients {
  margin-bottom: 15rem;
}
.company-logo {
  max-width: 25%;
  width: auto;
  height: 7rem;
}
.contact .MuiSvgIcon-root {
  fill: white;
}
.contact:not(.contact-info) {
  margin-bottom: 15rem;
}
.contact.contact.contact-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contact-info  {
  margin-bottom: 15rem;
  width: 75%;
}
.contact-info  h1 {
  font-size: 1.5rem;
}
.contact-info p {
  margin: 0;
}
.drawerMenu .MuiButtonBase-root {
  background: transparent !important;
}
@media only screen and (max-width: 1024px) {
  .logos {
    flex-wrap: wrap;
  }
  .company-logo {
    height: 6rem;
    max-width: 50%;
    margin-bottom: 2rem;
  }
  .contact {
    scroll-margin-top: 8rem;
  }
  .contact h1 {
    font-size: 2.5rem;
  }
  .contact h2 {
    width: 100%;
  }
  .contact .MuiButtonBase-root {
    width: 50%;
    height: 2rem;
  }
  .contact-form .MuiButtonBase-root {
    width: 100%;
    height: 2rem;
  }
  .drawerMenu {
    width: 60vw;
  }

  .burger-button .MuiButtonBase-root.MuiButton-text {
    padding: 16px 8px;
  }
}
@media only screen and (max-width: 768px) {
  .contact .MuiButtonBase-root {
    width: 100%;
  }
}
