.case-card {
  width: 100%;
  pointer-events: none;
}

.case-card .media {
  width: 100%;
  height: 17rem;
}
.case-card .MuiCardActions-root {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.case-card .MuiCardContent-root p {
  font-weight: normal;
  text-align: right;
}

.case-card .MuiSvgIcon-root {
  fill: white;
}

.case-title {
  display: flex;
  flex-direction: column;
}

.case-card h3,
.case-card h4 {
  margin: 0;
  text-align: right;
}
.case-card h3 {
  font-size: .9rem;
}
.case-card h4 {
  font-size: .8rem;
}

@media only screen and (max-width: 768px) {
  .case-card h3 {
    font-size: .7rem;
  }
  .case-card h4 {
    font-size: .6rem;
  }
}