.header {
  width: 100%;
  height: 4rem;
  background: white;
  padding: 1rem 0;
  display: flex;
  justify-content: center; 
  position: sticky;
  position: -webkit-sticky; 
  top: 0;
  z-index: 999;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 70%;
}
.logo {
  height: 100%;
  width: auto;
}
.logo img {
  height: 100%;
}
.links {
  display: flex;
  align-items: center;
}
.links .MuiTypography-root {
  color: var(--primary-color);
  margin-left: 2rem;
}
.burger-button {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .nav-container { 
    width: 90%;
  }
  .drawerMenu .MuiTypography-body1 {
    font-size: 2rem;
  }
  .linkedin .MuiSvgIcon-root {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .nav-container { 
    width: 90%;
  }
  .links {
    display: none;
  }
  .burger-button {
    display: block
  }
}