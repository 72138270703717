.footer {
  width: 100%;
  height: 2rem;
  background: var(--primary-color);
  color: white;
  padding: 1rem 0;
  display: flex;
  justify-content: center; 
  align-items: center;
  position: sticky;
  z-index: 999;
  text-align: center;
}

.footer h2 {
  font-family: 'Roboto';
  font-size: .8rem;
  width: 33%;
}