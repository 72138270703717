.article {
  width: 70%;
  min-height: calc(100vh - 10rem);
  margin: 0 auto;
  text-align: justify;
}

.article h1 {
  margin: 0;
}

.author-date {
  margin-top: 0;
  font-weight: 300; 
  color: "#555";
}

.case-article {
  width: 70%;
  margin: 0 auto;
  text-align: justify;
}

.case-article .featured-image,
.article .featured-image {
  width: 50%;
  height: auto;
  float: left;
  margin-right: 1rem;
  margin-bottom: .5rem;
}

.article img {
  width: 50%;
  height: auto;
  margin-right: 1rem;
  margin-bottom: .5rem;
}

.case-article p:last-of-type,
.article p:last-of-type {
  margin-bottom: 5rem;
}

.case-article h1,
.article h1 {
  color: var(--primary-color)
}

@media only screen and (max-width: 768px) {
  .case-article,
  .article {
    width: 90%;
    text-align: justify;
  }
  .case-article .featured-image,
  .article .featured-image {
    float: unset;
    width: 100%;
  }
}