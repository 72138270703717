.contact-form .MuiButtonBase-root {
  margin-top: 2rem;
}
.contact-form {
  width: 70%; 
  margin: 0 auto;
  background: white;
  padding: 1rem;
  margin-top: 2rem;
}
@media only screen and (max-width: 768px) {
  .contact-form {
    width: 90%; 
  }
}