.home {
  width: 70%;
  margin: 0 auto;
}
.home:target {
  display: block;
  position: relative;
  top: -120px; 
  visibility: hidden;
}
/* Splash section */
.section {
  height: calc(100vh - 6rem);
  width: 80vw;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  transform: translateY(-2rem);
}
.splash .img {
  width: 70%;
  height: auto;
  z-index: -1; 
}
.splash .text {
  position: absolute;
  width: 35vw;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.2rem;
  filter: drop-shadow(-2px 2px 5px #aaa);
}
.section .MuiButtonBase-root {
  width: 27%;
  height: 2rem;
}
@media only screen and (max-width: 1024px) {
  .home {
    width: 90%;
  }
  .section {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: unset;
  }
  .splash .img {
    width: 80%;
  }
  .text { 
    display: flex;
    flex-direction: column;
    padding-right: unset;
    text-align: left;
  }
  .section .MuiButtonBase-root {
    width: 30%;
    height: 2rem;
  }
  .splash .text {
    position: relative;
    width: 100%;
    filter: none;
  }
}
@media only screen and (max-width: 768px) {
  .section .MuiButtonBase-root {
    width: 100%;
    height: 2rem;
  }
}

/* Selling points */
.selling-points {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15rem;
  scroll-margin-top: 10rem;
}
.selling-points .icon {
  width: 5rem;
  height: 5rem;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.icon .MuiSvgIcon-root {
  fill: white;
  height: 3rem;
  width: 3rem;
}
.selling-point {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}
.selling-point:not(last-of-type) {
  margin-bottom: 3rem;
}
.selling-points h1 {
  text-align: center;
  margin-bottom: 3rem;
}
.selling-point h2 {
  width: 85%;
  font-weight: normal;
  font-size: 1.4rem;
}
@media only screen and (max-width: 1024px) {
  .selling-points {
    scroll-margin-top: 8rem;
  }
  .selling-point { 
    align-items: flex-start;
  }
  .selling-point:not(last-of-type) {
    margin-bottom: 2rem;
  }
  .selling-points .icon {
    height: 3rem;
    width: 3rem;
  }
  .icon .MuiSvgIcon-root {
    height: 1rem;
    width: 1rem;
  }
  .selling-point h2 {
    width: 80%;
    margin:0;
    text-align: left;
  }
}
/* Mission */
.mission {
  margin: 2rem 0;
  margin-bottom: 15rem;
  scroll-margin-top: 10rem;
}
.mission h1 {
  color: var(--primary-color);
  margin: .5rem 0;
  font-size: 2rem;
}
.mission .text {
  display: flex;
  align-items: center;
  padding: 0;
  text-align: right;
}
.mission .text div {
  padding-left: 3rem;
  font-size: 1.4rem;
}
.mission .text img {
  width: 50%;
  height: auto;
}
.mission .text p:first-of-type {
  margin-top: 0;
}
.mission .text p:last-of-type {
  margin-bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .mission {
    scroll-margin-top: 8rem;
  }
  .mission .text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mission .text div {
    position: relative;
    left: unset;
    padding-left: unset;
    text-align: justify;
    width: 100%;
  }
  .mission .text img {
    width: 100%;
  }
}
/* Team */
.team {
  margin-bottom: 15rem;
  scroll-margin-top: 10rem;
}
.team h1 {
  color: var(--primary-color);
  margin: .5rem 0;
  font-size: 2rem;
}
.team p {
  font-size: 1.4rem;
}
.team-cards {
  display: flex;
  justify-content: center;
}
.team-cards .team-card {
  width: calc(100%/3);
  padding: 1rem;
}
.team-card .MuiCardContent-root {
  font-weight: bold;
  margin: 0 auto;
}
.team-card .MuiCardContent-root p {
  text-align: justify;
  padding: 0 1rem;
}
@media only screen and (max-width: 768px) {
  .team {
    scroll-margin-top: 8rem;
  }
  .team p {
    text-align: justify;
  }
  .team-cards {
    display: flex;
    flex-wrap: wrap;
  }
  .team-cards .team-card {
    width: 100%;
    padding: unset;
    margin-bottom: 1rem;
  }
}
/* Contact */
.contact {
  text-align: center;
  margin-bottom: 5rem;
  scroll-margin-top: 10rem;
}
.contact h1 {
  color: var(--primary-color);
  font-size: 2rem;
}
.contact h2 {
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: normal;
  width: 80%;
  margin-bottom: 2rem;
}
.logos {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0 auto;
}
.clients {
  margin-bottom: 15rem;
}
.company-logo {
  max-width: 25%;
  width: auto;
  height: 7rem;
}
.contact .MuiSvgIcon-root {
  fill: white;
}
.contact:not(.contact-info) {
  margin-bottom: 15rem;
}
.contact.contact.contact-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contact-info  {
  margin-bottom: 15rem;
  width: 75%;
}
.contact-info  h1 {
  font-size: 1.5rem;
}
.contact-info p {
  margin: 0;
}
.drawerMenu .MuiButtonBase-root {
  background: transparent !important;
}
@media only screen and (max-width: 1024px) {
  .logos {
    flex-wrap: wrap;
  }
  .company-logo {
    height: 6rem;
    max-width: 50%;
    margin-bottom: 2rem;
  }
  .contact {
    scroll-margin-top: 8rem;
  }
  .contact h1 {
    font-size: 2.5rem;
  }
  .contact h2 {
    width: 100%;
  }
  .contact .MuiButtonBase-root {
    width: 50%;
    height: 2rem;
  }
  .contact-form .MuiButtonBase-root {
    width: 100%;
    height: 2rem;
  }
  .drawerMenu {
    width: 60vw;
  }

  .burger-button .MuiButtonBase-root.MuiButton-text {
    padding: 16px 8px;
  }
}
@media only screen and (max-width: 768px) {
  .contact .MuiButtonBase-root {
    width: 100%;
  }
}