.team-card .media {
  width: 100%;
  height: 25rem;
}
.team-card .MuiCardActions-root {
  position: relative;
  display: flex;
}

.team-card h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;  
}

.team-card .MuiCardContent-root p {
  font-weight: normal;
  text-align: left;
}

.team-card .MuiSvgIcon-root {
  fill: white;
}
.linkedin .MuiSvgIcon-root {
  fill: var(--primary-color);
}
.linkedin.MuitButton-root {
  padding-left: 1rem;
}
.linkedin.MuiButtonBase-root {
  border-radius: 5px;
  background: transparent !important;
}
@media only screen and (max-width: 1024px) {
  .team-card h3 {
    font-size: .8rem;
    right: 3%;
    transform: translate(0,-50%);
  }
}
@media only screen and (max-width: 1024px) {
  .team-card h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%);
    margin: 0;  
    font-size: 1rem;
  }
}