.case {
  min-height: calc(100vh - 10rem);
  margin: 0 auto;
  width: 70%;
}

.case .title {
  text-align: center;
  color: var(--primary-color);
  font-size: 3rem;
}

.case .text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  position: relative;
}

.case .text p {
  margin-top: 0;
}

.cases {
  margin: 3rem 0;
}

.case-img {
  position: absolute;
  width: 15rem;
  height: auto;
  float: right;
  z-index: 10;
  right: -10%;
  bottom: -5%;
}
.coverflow-container {
  position: relative;
}

.case-text {
  margin-bottom: 5rem;
}

.cases {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;
}
.case .swiper-container {
  padding-bottom: 3rem;
}

.case .swiper-slide.swiper-slide-prev,
.case .swiper-slide.swiper-slide-next {
  pointer-events: none;
}

.case .swiper-slide.swiper-slide:not(.swiper-slide-active) .case-title {
  opacity: 0;
  transition: opacity .5s ease;
}

.case .swiper-slide.swiper-slide-active::before {
  opacity: 0;
  transition: opacity .5s ease;
}
.case .swiper-slide.swiper-slide-active {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .case {
    width: 90%;
  }
  .case .text div {
    margin-left: 0;
  }
  .case .title {
    text-align: left;
  }
}