@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

html, body {
  width: 100%;
  height: 100%;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f8ff;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #114bb2;
  --text-color: #333;
}

.wave {
  background: white;
  width: 100%;
  height: 40rem;
  position: relative;
}
.wave:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(./images/wave.svg);
  background-size: cover;
  width: 100%;
  height: 20rem;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40rem;
  z-index: -1;
}
.MuiButtonBase-root {
  background-color: var(--primary-color) !important;
}
.MuiButton-root {
  color: white !important; 
  border-radius: 0;
  width: 12vw;
  height: 2vw;
  font-size: .8vw;
}
